import request from '../utils/request'

export interface IHostConfig {
    clientId?: string
    // 用作接口请求的域名
    baseUrl?: string
    // 用作路由跳转的域名
    mainUrl?: string
}
const m1Config = {
    clientId: 'wk-h5-new',
    baseUrl: 'https://apiv1.lizhiweike.com',
    mainUrl: 'https://m1.lizhiweike.com'
}
const defaultConfig: IHostConfig = {
    baseUrl: 'https://apiv1.lizhiweike.com',
    mainUrl: 'https://m.lizhiweike.com'
}

const hostConfig: {[host: string]: IHostConfig}  = {
    'm1.lizhiweike.com': m1Config,
    'm.lizhiweike.com' : defaultConfig
}

// 获取预设clientId
export function getPresetClientId() {
    const presetConfig = getPresetConfig()
    if (!presetConfig) {
        return
    }
    return presetConfig.clientId
}

/**
 * hostname 对应预设的接口请求host
 */
export function getPresetBaseAPiHost() {
    const presetConfig = getPresetConfig()
    if (!presetConfig) {
        return
    }
    return presetConfig.baseUrl
}

/**
 * hostname 对应预设的路由跳转host
 */
export function getPresetMainUrl() {
    const presetConfig = getPresetConfig()
    if (!presetConfig) {
        return
    }
    return presetConfig.mainUrl
}

/**
 * 更近当前页面访问的URL，获取对应的预设配置
 */
export function getPresetConfig(): IHostConfig | undefined {
    const hostname = window.location.hostname
    return hostConfig[hostname]
}

export type TMainUrlPromise = Promise<{mainUrl: string, headStatus: boolean, reason?: string | Error}>
let mainUrlPromise: TMainUrlPromise | null = null

/**
 * 获取跳转主站的路径，mainUrl，
 * 默认可用：先去head defaultConfig.mainUrl（https://m.lizhiweike.com） 如果可用就返回 defaultConfig.mainUrl
 * 备用： 如果head 失败或超时（3000ms），就返回 m1Config.mainUrl （https://m1.lizhiweike.com）
 */
export async function getRtMainUrl() {
    if (mainUrlPromise) {
        return mainUrlPromise
    }
    mainUrlPromise = new Promise((resolve, reject) => {
        if (!defaultConfig.mainUrl) {
            return reject('')
        }
        request({
            method: 'HEAD',
            url: `${defaultConfig.mainUrl}/checkcors`
        }, () => {
            resolve({mainUrl: defaultConfig.mainUrl as string, headStatus: true})
        }, (e) => {
            if (e && e.status && e.status !== 404) {
                // 域名能请求成功都算OK
                resolve({mainUrl: defaultConfig.mainUrl as string, headStatus: true})
                return
            }
            resolve({mainUrl: m1Config.mainUrl, headStatus: false, reason: e})
        })
        setTimeout(() => {
            resolve({mainUrl: m1Config.mainUrl, headStatus: false, reason: 'timeout'})
        }, 3000)
    })

    return mainUrlPromise
}
