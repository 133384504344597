// tslint:disable-next-line
export const noop = () => {
}

export const throwErr = (err: any) => {
    throw err
}

function tryParseJson(data: string): any {
    // 以数字或者字母开头的 string 直接返回
    // JSON.parse('123abc') 会返回 Infinity
    if (/^\w.*/.test(data)) {
        return data
    }
    try {
        return JSON.parse(data)
    } catch (e) {
        return data
    }
}

export function generateQueryString(data: { [key: string]: any }): string {
    return Object.getOwnPropertyNames(data)
        .filter((name) => data[name] !== undefined)
        .map((name) => `${encodeURIComponent(name)}=${encodeURIComponent(data[name])}`).join('&')
}

export function parseQueryString(url: string): { [key: string]: any } {
    const queryString = url.split('?')[1]
    if (!queryString) {
        return {}
    }
    return (queryString
            .split('&')
            .map((item) => item.split('='))
            .map(([key, value]) => ({
                [decodeURIComponent(key)]: tryParseJson(decodeURIComponent(value))
            }))
            .reduce((perv, curr) => ({...perv, ...curr}), {})
    )
}

function parseResponse(req: XMLHttpRequest) {
    const responseText = req && req.responseText
    return tryParseJson(responseText)
}

export class RequestError extends Error {
    public readonly status: number
    public readonly body: any

    constructor(req: XMLHttpRequest) {
        super(`[Request] Request error with status ${req.status}`)
        this.status = req.status
        this.body = parseResponse(req)
    }
}

export interface IBaseRequestOptions {
    url: string
    baseUrl?: string
    query?: { [key: string]: any }
    xhr?: () => XMLHttpRequest
    headers?: { [name: string]: string }
    withCredentials?: boolean
    stop?: (req: XMLHttpRequest) => any
    start?: (req: XMLHttpRequest) => any
}

export interface IGetRequestOptions extends IBaseRequestOptions {
    method: 'GET'
}

export interface IHeadRequestOptions extends IBaseRequestOptions {
    method: 'HEAD'
}

export interface IPostRequestOptions extends IBaseRequestOptions {
    method: 'POST'
    data?: any
    dataType?: 'raw' | 'json' | 'form'
}

export type IRequestOptions = IGetRequestOptions | IPostRequestOptions | IHeadRequestOptions

export type OnRequestSuccess = (data: any) => any
export type OnRequestFailed = (err: RequestError) => any

export interface IRequest {
    (opts: IRequestOptions, onSuccess?: OnRequestSuccess, onFailed?: OnRequestFailed): void

    stop?: (req: XMLHttpRequest, opts: IRequestOptions) => any
    start?: (req: XMLHttpRequest, opts: IRequestOptions) => any
}

const request: IRequest = (
    opts: IRequestOptions,
    onSuccess: OnRequestSuccess = noop,
    onFailed: OnRequestFailed = throwErr
) => {
    const req = opts.xhr ? opts.xhr() : new XMLHttpRequest()

    let url = opts.url
    if (opts.query) {
        const query = {...parseQueryString(opts.url), ...(opts.query)}
        url = opts.url.replace(/\?.*$/, '') + '?' + generateQueryString(query)
    }

    if (opts.withCredentials) {
        req.withCredentials = true
    }

    req.onreadystatechange = () => {
        if (req.readyState === 4) {
            if (req.status >= 200 && req.status < 300) {
                onSuccess(parseResponse(req))
            } else {
                onFailed(new RequestError(req))
            }
            if (request.stop) {
                request.stop(req, opts)
            }
            if (opts.stop) {
                opts.stop(req)
            }
        }
    }

    req.open(opts.method, opts.baseUrl ? opts.baseUrl + url : url, true)

    if (opts.headers) {
        const headers = opts.headers
        Object.getOwnPropertyNames(opts.headers)
            .forEach((name) => req.setRequestHeader(name, headers[name]))
    }

    let data
    if (opts.method === 'POST') {
        if (opts.dataType === 'json') {
            req.setRequestHeader('Content-Type', 'application/json')
            data = JSON.stringify(opts.data)
        } else if (opts.dataType === 'form') {
            req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
            data = generateQueryString(opts.data)
        } else {
            data = opts.data
        }
    }

    if (request.start) {
        request.start(req, opts)
    }
    if (opts.start) {
        opts.start(req)
    }

    req.send(data)
}

export default request
